import Script from 'next/script'
import { useEffect, useState } from 'react';

export type GoogleAnalyticsEventName =
  | "complete_online_reception"
  | "complete_homevisit_reception"
  | "complete_inbound_reception";


export const gaTrackingId = process.env.NEXT_PUBLIC_DEV === "dev"
  ? 'G-NVBTHQK843'
  : 'G-47DMFG5JGK';

// gtagの存在確認
export const useGtagScriptLoaded = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const checkReady = setInterval(() => {
      if (typeof window.gtag !== 'undefined') {
        setReady(true);
        clearInterval(checkReady);
      }
    }, 100);

    return () => {
      clearInterval(checkReady);
    }
  }, []);

  return ready;
};

export const GoogleTagManagerScripts = () => {
  if (process.env.NEXT_PUBLIC_DEV === "dev") {
    return stgGoogleTagManagerScripts();
  } else {
    return prodGoogleTagManagerScripts();
  }
};

export const GoogleAnalyticsSendEvent = (
  eventName: GoogleAnalyticsEventName,
  params: { [key: string]: string }
): void => {
  window.gtag("event", eventName, params);
};

export const GoogleAnalyticsSendConversionEvent = (category: 'online' | 'homeVisit'): void => {
  window.gtag('event', 'click', {
    'event_category': category,
    'event_label': 'complete'
  });
}

const prodGoogleTagManagerScripts = () => {
  return (
    <>
      {/* Global site tag (gtag.js) - Google Analytics */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=UA-74512193-1"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {
          `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-74512193-1',{
            'optimize_id': 'GTM-PNQT8XB',
          'transport_type': 'beacon',
          'linker': {
            'domains': ['fastdoctor.jp', 'oncall.fastdoctor.jp', 'contact.fastdoctor.jp']
            }
          });
          gtag('config', 'AW-921521785');  // Google広告`
        }
      </Script>
      {/* Google Tag Manager */}
      <Script id="google-tag-manager" strategy="afterInteractive">
        {
          `(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KPMHWQP');`
        }
      </Script>
    </>
  );
};

const stgGoogleTagManagerScripts = () => {
  return (
    <>
      {/* Google tag (gtag.js) */}
      <Script
        async src="https://www.googletagmanager.com/gtag/js?id=G-NVBTHQK843"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {
          `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-NVBTHQK843');`
        }
      </Script>
      {/* Google Tag Manager */}
      <Script id="google-tag-manager" strategy="afterInteractive">
        {
          `(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KPMHWQP');`
        }
      </Script>
    </>
  );
};
