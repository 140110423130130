import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from '../config/locales/en/inboundform.json';
import jaTranslation from '../config/locales/ja/inboundform.json';
import zhChTranslation from "../config/locales/zh-ch/inboundform.json";
import zhTwTranslation from "../config/locales/zh-tw/inboundform.json";
import koTranslation from "../config/locales/ko/inboundform.json";

import enAxaTranslation from '../config/locales/axaForm/en/axaform.json';
import jaAxaTranslation from '../config/locales/axaForm/ja/axaform.json';
import zhChAxaTranslation from "../config/locales/axaForm/zh-ch/axaform.json";
import zhTwAxaTranslation from "../config/locales/axaForm/zh-tw/axaform.json";
import koAxaTranslation from "../config/locales/axaForm/ko/axaform.json";

const resources = {
    en: {
        inboundform: enTranslation,
        axaform: enAxaTranslation,
    },
    ja: {
        inboundform: jaTranslation,
        axaform: jaAxaTranslation,
    },
    zhch: {
        inboundform: zhChTranslation,
        axaform: zhChAxaTranslation,
    },
    zhtw: {
        inboundform: zhTwTranslation,
        axaform: zhTwAxaTranslation,
    },
    ko: {
      inboundform: koTranslation,
      axaform: koAxaTranslation,
    },   
  };

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    ns: ['inboundform', 'axaform'],
    defaultNS: 'inboundform',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: resources,
  });

export default i18n;