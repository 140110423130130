import Head from "next/head";
import { AppProps } from "next/app";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider, EmotionCache } from "@emotion/react";
import theme from "theme";
import createEmotionCache from "createEmotionCache";
import "tailwind.css";
import "i18n";
import { GoogleTagManagerScripts } from "components/googleTagManager";
import { OptimizeNextScripts } from "components/optimize-next";
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const MyApp = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <script async src="https://s.yimg.jp/images/listing/tool/cv/ytag.js"></script>
      </Head>
      <OptimizeNextScripts />
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
      <GoogleTagManagerScripts />
    </CacheProvider>
  );
};
export default MyApp;
