import Script from 'next/script'

export const OptimizeNextScripts = () => {
  if (process.env.NEXT_PUBLIC_DEV === "dev") {
    return stgOptimizeNextScript();
  } else {
    return prodOptimizeNextScript();
  }
};

const stgOptimizeNextScript = () => {
  return (
    <>
      {/* Optimize Next */}
      <Script id="optimize-next" strategy="beforeInteractive" >
        {
          `(function(p,r,o,j,e,c,t,g){
            p['_' + t] = {};
            g=r.createElement('script');
            g.src='https://www.googletagmanager.com/gtm.js?id=GTM-'+t;
            r[o].prepend(g);
            g=r.createElement('style');
            g.innerText='.'+e+t+'{visibility:hidden!important}';
            r[o].prepend(g);
            r[o][j].add(e+t);
            setTimeout(function(){
              if(r[o][j].contains(e+t)){
                r[o][j].remove(e + t);
                p['_'+t]=0
              }
            },c)
          })(window,document,'documentElement','classList','loading',2000,'NCRJR25B')`
        }
      </Script>
    </>
  );
};

const prodOptimizeNextScript = () => {
  return (
    <>
      {/* Optimize Next */}
      <Script id="optimize-next" strategy="beforeInteractive" >
        {`
          (function(p,r,o,j,e,c,t,g){
          p['_'+t]={};g=r.createElement('script');g.src='https://www.googletagmanager.com/gtm.js?id=GTM-'+t;r[o].prepend(g);
          g=r.createElement('style');g.innerText='.'+e+t+'{visibility:hidden!important}';r[o].prepend(g);
          r[o][j].add(e+t);setTimeout(function(){if(r[o][j].contains(e+t)){r[o][j].remove(e+t);p['_'+t]=0}},c)
          })(window,document,'documentElement','classList','loading',2000,'PSGWB7JW')
        `}
      </Script>
    </>
  );
};
